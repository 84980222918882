import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
const Loader = props => {
    const { center, color } = props;
    const classes = useStyles();
    const container = clsx(center && classes.center);
    return (
        <div className={container}>
            <CircularProgress color={color} />
        </div>
    );
};

Loader.defaultProps = {
    center: true,
    color: 'primary',
};

Loader.propTypes = {
    center: PropTypes.bool,
    modal: PropTypes.bool,
    color: PropTypes.string,
};

export default Loader;
