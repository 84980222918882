import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BackButton from './../buttons/back';
import ErrorButton from './../buttons/error';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));
const Confirmation = props => {
    const { title, body, onYes, onNo, open } = props;
    const classes = useStyles();

    const handleNo = () => {
        onNo();
    };

    const handleYes = () => {
        onYes();
    };

    return (
        <Dialog
            open={open}
            onClose={handleNo}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <BackButton onClick={handleNo}>NO</BackButton>
                <ErrorButton onClick={handleYes}>YES</ErrorButton>
            </DialogActions>
        </Dialog>
    );
};

Confirmation.defaultProps = {
    title: 'Confirmation',
    body: 'Do you really want to perform this action?',
    onYes: () => console.log('Please provide a onYes() function.'),
    onNo: () => console.log('Please provide a onNo() function.'),
    open: false,
};

Confirmation.propTypes = {
    title: PropTypes.string,
    body: PropTypes.string,
    onYes: PropTypes.func,
    onNo: PropTypes.func,
    open: PropTypes.bool.isRequired,
};

export default Confirmation;
