import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LoginRedirect from './login';
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
}));
const Auth = props => {
    const classes = useStyles();
    const { children, loggedIn, LoginPage } = props;

    if (!loggedIn) {
        return LoginPage;
    }
    return children;
};

const login = () => {
    window.location.href = '/login';
};

Auth.defaultProps = {
    LoginPage: <LoginRedirect onLogin={login} />,
};

Auth.propTypes = {
    children: PropTypes.node.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    LoginPage: PropTypes.element,
};

export { default as Avatar } from './avatar';
export { default as Login } from './login';
export default Auth;
