import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    margin: {
        marginBottom: theme.spacing(2),
    },
}));

const Header = props => {
    const { title, marginBottom } = props;
    const classes = useStyles();
    const style = clsx((marginBottom || true) && classes.margin);
    return (
        <div className={style}>
            <Typography variant="h3" color="textPrimary" gutterBottom>
                {title}
            </Typography>
            <Divider />
        </div>
    );
};

Header.propTypes = {
    title: PropTypes.string,
};

export default Header;
