import React from 'react';
import PropTypes from 'prop-types';
import { Container as MUIContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    padding: {
        padding: theme.spacing(2),
    },
}));
export const Container = props => {
    const { maxWidth, padding, className } = props;
    const classes = useStyles();
    const style = clsx(padding && classes.padding, className);
    return (
        <MUIContainer maxWidth={maxWidth} className={style}>
            {props.children}
        </MUIContainer>
    );
};

Container.propTypes = {
    padding: PropTypes.bool,
    maxWidth: PropTypes.string,
    className: PropTypes.string,
};

export default Container;
