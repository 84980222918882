import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Visa from './../assets/images/brands/visa.png';
import Maestro from './../assets/images/brands/maestro.png';
import Mastercard from './../assets/images/brands/mastercard.png';
import Sepa from './../assets/images/brands/sepa.png';
import Vpay from './../assets/images/brands/vpay.png';
import Amex from './../assets/images/brands/amex.png';
const brands = {
    visa: { icon: Visa },
    maestro: { icon: Maestro },
    mastercard: { icon: Mastercard },
    sepa: { icon: Sepa, title: 'Sepa ELV' },
    elv: { icon: Sepa, title: 'ELV' },
    vpay: {
        icon: Vpay,
    },
    amex: {
        icon: Amex,
        title: 'American Express',
    },
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    brand: props => ({
        height: props.size,
        width: props.size,
        backgroundImage: `url(${brands[props.brand].icon})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    }),
}));
const BrandIcon = props => {
    const { brand } = props;
    const classes = useStyles(props);
    return (
        <div
            className={classes.brand}
            title={(brands[brand].title || brand).toUpperCase()}
        />
    );
};

BrandIcon.defaultProps = {
    size: 40,
};

BrandIcon.propTypes = {
    brand: PropTypes.oneOf([
        'visa',
        'maestro',
        'mastercard',
        'sepa',
        'vpay',
        'amex',
        'elv',
    ]).isRequired,
    size: PropTypes.number,
};

export default BrandIcon;
