import tinycolor from 'tinycolor2';

const primary = '#2f323a';
const secondary = '#d10019';
const warning = '#ffe692';
const success = '#8EC089';
const info = '#9013FE';
const error = '#E60000';

const lightenRate = 5;
const darkenRate = 5;

export default {
    themeName: 'Default',
    palette: {
        primary: {
            main: primary,
            light: tinycolor(primary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(primary)
                .darken(darkenRate)
                .toHexString(),
        },
        secondary: {
            main: secondary,
            light: tinycolor(secondary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(secondary)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#FFFFFF',
        },
        error: {
            main: error,
            light: tinycolor(error)
                .lighten(40)
                .toHexString(),
            dark: tinycolor(error)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#FFF',
        },
        warning: {
            main: warning,
            light: tinycolor(warning)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(warning)
                .darken(darkenRate)
                .toHexString(),
        },
        success: {
            main: success,
            light: tinycolor(success)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(success)
                .darken(darkenRate)
                .toHexString(),
            contrastText: '#FFF',
        },
        info: {
            main: info,
            light: tinycolor(info)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(info)
                .darken(darkenRate)
                .toHexString(),
        },
        text: {
            primary: '#4A4A4A',
            secondary: '#6E6E6E',
            hint: '#B9B9B9',
        },
        background: {
            default: '#f3f5f6',
            light: '#F3F5FF',
        },
    },
    status: {
        success: success,
        warning: warning,
        error: error,
    },
    customShadows: {
        widget:
            '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetDark:
            '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
        widgetWide:
            '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    },
    props: {
        MuiPaper: {
            elevation: '4',
        },
        MuiCard: {
            elevation: '4',
        },
    },
};
