import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Avatar } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {},
    avatar: props => ({
        width: props.size,
        height: props.size,
    }),
}));
const Profile = props => {
    const { profile, onClick } = props;
    const classes = useStyles(props);

    if (onClick) {
        return (
            <IconButton aria-label="profile" onClick={onClick}>
                {profile.avatar && (
                    <Avatar
                        alt={profile.displayName || profile.username}
                        title={profile.displayName || profile.username}
                        src={profile.avatar}
                        className={classes.avatar}
                    />
                )}
                {!profile.avatar && <AccountCircleIcon />}
            </IconButton>
        );
    } else {
        return (
            <React.Fragment>
                {profile.avatar && (
                    <Avatar
                        alt={profile.displayName || profile.username}
                        title={profile.displayName || profile.username}
                        src={profile.avatar}
                        className={classes.avatar}
                    />
                )}
                {!profile.avatar && <AccountCircleIcon />}
            </React.Fragment>
        );
    }
};

Profile.defaultProps = {
    size: 40,
    profile: {},
};

Profile.propTypes = {
    profile: PropTypes.object,
    onClick: PropTypes.func,
    size: PropTypes.number,
};

export default Profile;
