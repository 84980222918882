import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';

const styles = theme => ({
    card: {
        minWidth: 600,
    },
});

const REDIRECT_INTERVAL = 3;

export class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds: REDIRECT_INTERVAL,
            redirecting: false,
        };
    }

    componentDidMount() {
        this.loginHandler = setTimeout(() => {
            this.login();
        }, REDIRECT_INTERVAL * 1000);

        this.timeoutHandler = setInterval(() => {
            this.setState({ seconds: this.state.seconds - 1 });
        }, 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutHandler);
        clearTimeout(this.loginHandler);
    }

    login = event => {
        this.setState({ redirecting: true });
        event && event.preventDefault();
        this.props.onLogin && this.props.onLogin();
    };

    render() {
        const { classes } = this.props;

        return (
            <Card className={classes.card}>
                <CardHeader title="Authentication"></CardHeader>
                <CardContent>
                    {!this.state.redirecting && (
                        <React.Fragment>
                            <Typography color="textPrimary" gutterBottom>
                                You will be redirect to SION for the login
                                procedure in&nbsp;{this.state.seconds} seconds.
                            </Typography>
                            <Typography color="textSecondary" gutterBottom>
                                <Link
                                    href="#"
                                    onClick={this.login}
                                    color="inherit"
                                >
                                    Click here if you don't want to wait.
                                </Link>
                            </Typography>
                        </React.Fragment>
                    )}
                    {this.state.redirecting && (
                        <Typography color="textPrimary" gutterBottom>
                            Redirecting to SION...
                        </Typography>
                    )}
                </CardContent>
            </Card>
        );
    }
}

LoginForm.propTypes = {
    onLogin: PropTypes.func.isRequired,
};

export default withStyles(styles)(LoginForm);
