import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, fade } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: 300,
    },
    inputInput: {
        transition: theme.transitions.create('width'),
        paddingLeft: theme.spacing(7),
        [theme.breakpoints.up('md')]: {
            width: 300,
        },
    },
}));
const Search = props => {
    const { onInput, options, loading } = props;
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState(null);

    const changeHandler = event => {
        setSearchValue(event.target.value);
        onInput(event.target.value);
    };

    return (
        <div className={classes.search}>
            <Autocomplete
                id="search"
                freeSolo
                options={options}
                getOptionLabel={option => option.title}
                className={classes.inputRoot}
                renderInput={params => (
                    <React.Fragment>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <TextField
                            {...params}
                            placeholder="Search…"
                            className={classes.inputInput}
                            fullWidth
                            onChange={changeHandler}
                        />
                    </React.Fragment>
                )}
            />
        </div>
    );
};

Search.defaultProps = {
    options: [],
    loading: false,
};

Search.propTypes = {
    onInput: PropTypes.func.isRequired,
    options: PropTypes.array,
    loading: PropTypes.bool,
};

export default Search;
