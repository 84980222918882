import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { DeleteIcon } from '../../icons';
import { makeStyles } from '@material-ui/core/styles';
import Confirmation from './../../dialogs/confirmation';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.light,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.error.light,
            },
        },
    },
}));

const Delete = props => {
    const { onClick, confirmationTitle, confirmationBody } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleNo = () => {
        setOpen(false);
    };

    const handleYes = () => {
        setOpen(false);
        onClick && onClick();
    };

    return (
        <React.Fragment>
            <IconButton
                className={classes.root}
                {...props}
                onClick={handleClickOpen}
            >
                <DeleteIcon></DeleteIcon>
            </IconButton>
            <Confirmation
                open={open}
                onNo={handleNo}
                onYes={handleYes}
                title={confirmationTitle}
                body={confirmationBody}
            ></Confirmation>
        </React.Fragment>
    );
};

Delete.defaultProps = {
    confirmationTitle: 'Confirmation',
    confirmationBody: 'Do you really want to delete this item?',
    onClick: () => console.log('Please provide onClick() func.'),
};

Delete.propTypes = {
    onClick: PropTypes.func,
    confirmationTitle: PropTypes.string,
    confirmationBody: PropTypes.string,
    confirmationYes: PropTypes.string,
    confirmationNo: PropTypes.string,
};

export default Delete;
