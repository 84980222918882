import React from 'react';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const Button = withStyles(theme => ({
    root: props => {
        if (props.disabled) {
            return {
                backgroundColor: theme.palette.grey['200'],
            };
        } else {
            return {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: theme.palette.primary.main,
                    },
                },
            };
        }
    },
}))(MuiButton);

const PrimaryButton = props => {
    return (
        <Button variant="contained" {...props}>
            {props.children}
        </Button>
    );
};

export default PrimaryButton;
