import React from 'react';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PrimaryButton from './primaryButton';

const Button = withStyles(theme => ({
    root: props => {
        if (props.variant === 'outlined') {
            return {
                color: theme.palette.success.main,
                borderColor: theme.palette.success.main,
                '&:hover': {
                    backgroundColor: theme.palette.success.light,
                    borderColor: theme.palette.success.dark,
                    color: '#FFF',
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: theme.palette.success.light,
                    },
                },
            };
        } else {
            return {
                color: theme.palette.success.contrastText,
                backgroundColor: theme.palette.success.main,
                '&:hover': {
                    backgroundColor: theme.palette.success.dark,
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: theme.palette.success.main,
                    },
                },
            };
        }
    },
}))(MuiButton);

const SaveButton = props => {
    return props.disabled ? (
        <PrimaryButton variant="contained" {...props}>
            {props.children}
        </PrimaryButton>
    ) : (
        <Button variant="contained" {...props}>
            {props.children}
        </Button>
    );
};

export default SaveButton;
