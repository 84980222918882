import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Search from './components/search';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2),
        },
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title: props => ({
        [theme.breakpoints.up('sm')]: {
            marginRight: props.title ? theme.spacing(3) : 0,
        },
    }),
    grow: {
        flexGrow: 1,
    },
}));

export const MiddleComponent = props => {
    const classes = useStyles();

    return <div className={classes.grow}></div>;
};

export const Bar = props => {
    const {
        homePath,
        leftComponent,
        rightComponent,
        middleComponent,
        color,
        menu,
        onMenu,
        open,
        title,
        style,
    } = props;

    const onMenuHandler = () => {
        onMenu();
    };

    const classes = useStyles(props);
    return (
        <AppBar
            position={props.position || 'static'}
            color={color}
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
            style={style}
        >
            <Toolbar>
                {menu && !open && (
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={onMenuHandler}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Typography variant="h6" className={classes.title} noWrap>
                    <Link href={homePath} color="inherit">
                        {title}
                    </Link>
                </Typography>
                {leftComponent}
                {middleComponent}
                {rightComponent}
            </Toolbar>
        </AppBar>
    );
};

// Specifies the default values for props:
Bar.defaultProps = {
    homePath: '/',
    color: 'primary',
    menu: false,
    open: false,
    onMenu: () => console.log('Please provide an onMenu() function.'),
    position: 'static',
    middleComponent: <MiddleComponent />,
};

Bar.propTypes = {
    title: PropTypes.string,
    position: PropTypes.string,
    homePath: PropTypes.string,
    rightComponent: PropTypes.element,
    middleComponent: PropTypes.element,
    leftComponent: PropTypes.element,
    color: PropTypes.string,
    menu: PropTypes.bool,
    onMenu: PropTypes.func,
    open: PropTypes.bool,
};

export default Bar;
