import React from 'react';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PrimaryButton from './primaryButton';

const Button = withStyles(theme => ({
    root: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.primary.main,
            },
        },
    },
}))(MuiButton);

const BackButton = props => {
    return props.disabled ? (
        <PrimaryButton variant="contained" {...props}>
            {props.children}
        </PrimaryButton>
    ) : (
        <MuiButton variant="contained" {...props}>
            {props.children}
        </MuiButton>
    );
};

export default BackButton;
