import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { EditIcon } from '../../icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.primary.light,
            },
        },
    },
}));

const Delete = props => {
    const classes = useStyles();
    return (
        <IconButton className={classes.root} {...props}>
            <EditIcon></EditIcon>
        </IconButton>
    );
};

export default Delete;
