import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Loader from './loader';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));
const PageLoader = props => {
    const { children, ready } = props;
    const classes = useStyles();

    return ready ? children : <Loader />;
};

PageLoader.propTypes = {
    ready: PropTypes.bool.isRequired,
};

export default PageLoader;
